import { Stack, Button, Dropdown, MenuButton, Menu, MenuItem } from '@mui/joy';
import { NoteAdd, MoreVert } from '@mui/icons-material';
import { email } from '../modules/firebase';

const HeaderActions = ({
  querying,
  messages,
  user,
  setConfirmChatDeletion,
  setSendingEmail,
  setEmailSent
}) => {
  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        p: 1,
        zIndex: 1000
      }}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Button
        startDecorator={<NoteAdd />}
        size="sm"
        disabled={querying || messages.length <= 1}
        variant="soft"
        onClick={() => setConfirmChatDeletion(true)}
      >
        New chat
      </Button>
      <Dropdown>
        <MenuButton size="sm">
          <MoreVert />
        </MenuButton>
        <Menu>
          <MenuItem
            disabled={querying || messages.length <= 1}
            onClick={async () => {
              setSendingEmail(true);
              const { subject } = await email({ user, messages });
              setEmailSent(subject);
              setSendingEmail(false);
            }}
          >
            Email this chat to yourself
          </MenuItem>
          {/* <MenuItem onClick={async () => {}} selected>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Private chat
          </MenuItem> */}
        </Menu>
      </Dropdown>
    </Stack>
  );
};

export default HeaderActions;
