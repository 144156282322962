import { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { onSnapshot, getFirestore, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext(null);

const useUser = () => {
  return useContext(UserContext);
};

const UserProvider = props => {
  const [userAuth, setUserAuth] = useState();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let unsub;

    if (userAuth) {
      console.log('User auth state changed. Authd user is', userAuth.uid);
      unsub = onSnapshot(
        doc(getFirestore(), 'users', userAuth.uid),
        async docSnapshot => {
          console.log(
            'Got new data from user snapshot in Firestore',
            docSnapshot.data()
          );

          setUser({
            ...docSnapshot.data(),
            id: docSnapshot.id
          });
        }
      );
    } else {
      setUser(null);
    }

    return () => {
      unsub && unsub();
    };
  }, [userAuth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      setLoading(false);
      setUserAuth(user);
      if (user) {
        console.log('Auth state changed. Authd user is', user.email);
        navigate('/dashboard');
      } else {
        console.log('User logged out, so redirecting to /');
        navigate('/');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <UserContext.Provider
      value={{
        user,
        loading
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { useUser, UserProvider };
