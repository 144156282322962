import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Input
} from '@mui/joy';

const YouTubeModal = ({
  open,
  youtubeUrl,
  setYoutubeUrl,
  handleYouTubeTranscription,
  handleClose
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog>
        <DialogTitle>Insert a YouTube transcript</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Input
            placeholder="Paste YouTube URL here"
            value={youtubeUrl}
            autoFocus
            onChange={e => setYoutubeUrl(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYouTubeTranscription} disabled={!youtubeUrl}>
            Transcribe
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default YouTubeModal;
