import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  getResponse,
  convertPDFtoText,
  transcribeYouTubeVideo
} from '../modules/firebase';

const createSystemMessage = user => {
  return `${
    user.council.length === 1
      ? `You are ${user.council[0]}. Speak as they would speak, and answer from their unique perspective.`
      : `You are the collective council comprising of ${user.council.join(
          ', '
        )}. Respond to messages with a unified voice that embodies the collective wisdom and distinct philosophical perspectives of the council members.`
  } Address responses to ${user.name}, considering their preferences for ${
    user.preferences
  }${
    user.accountType === 'sage' && user.learning
      ? `, and what we've already learnt about them: ${user.aggregatedMessagesDetails}`
      : ''
  }. Today's date is ${new Date().toUTCString()}.`;
};

export const handleImageUpload = async (
  event,
  setOpenImageDialog,
  setQuerying,
  user,
  messages,
  currentSessionId,
  updateChatMessages,
  setSnackbarMessage
) => {
  setOpenImageDialog(false);
  if (event.target.files && event.target.files[0]) {
    setQuerying(true);
    const file = event.target.files[0];
    const storageRef = ref(getStorage(), `images/${file.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, file, {
        customMetadata: {
          userId: user.id,
          name: user.name,
          email: user.email
        }
      });

      const url = await getDownloadURL(snapshot.ref);

      const imageMessage = {
        role: 'user',
        content: [{ type: 'image_url', image_url: { url: url } }]
      };

      const updatedMessages = [...messages, imageMessage];
      await updateChatMessages(user.id, currentSessionId, updatedMessages);

      const systemMessage = createSystemMessage(user);
      const response = await getResponse(updatedMessages, systemMessage);
      console.log(response);

      setQuerying(false);
      await updateChatMessages(user.id, currentSessionId, [
        ...updatedMessages,
        { role: 'assistant', content: response }
      ]);
    } catch (error) {
      console.error('Error uploading image:', error);
      setSnackbarMessage(
        'The Collective Council was distracted by a butterfly, so processing your image took a detour. 🦋 Try again later.'
      );
      setQuerying(false);
    }
  }
};

export const handleYouTubeTranscription = async (
  youtubeUrl,
  setQuerying,
  setOpenYouTubeDialog,
  user,
  messages,
  currentSessionId,
  updateChatMessages,
  setSnackbarMessage,
  setYoutubeUrl
) => {
  if (youtubeUrl) {
    setQuerying(true);
    setOpenYouTubeDialog(false);
    try {
      const transcription = await transcribeYouTubeVideo(youtubeUrl);
      const youTubeText = {
        role: 'user',
        content: `(transcript) ${transcription}`
      };
      const updatedMessages = [...messages, youTubeText];
      await updateChatMessages(user.id, currentSessionId, updatedMessages);
      const systemMessage = createSystemMessage(user);
      const response = await getResponse(updatedMessages, systemMessage);
      console.log('response', response);
      setQuerying(false);
      console.log(updatedMessages, response);
      await updateChatMessages(user.id, currentSessionId, [
        ...updatedMessages,
        { role: 'assistant', content: response }
      ]);
    } catch (error) {
      console.error('Error transcribing YouTube audio:', error);
      setSnackbarMessage('Error transcribing YouTube audio.');
      setQuerying(false);
    }
    setYoutubeUrl('');
  }
};

export const handleFileConvert = async (
  fileToConvert,
  setQuerying,
  setOpenDialog,
  user,
  messages,
  currentSessionId,
  updateChatMessages,
  setSnackbarMessage,
  setFileToConvert
) => {
  setOpenDialog(false);
  if (fileToConvert) {
    setQuerying(true);
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      try {
        const textResponse = await convertPDFtoText(base64String);
        const pdfText = {
          role: 'user',
          content: `(text extracted) ${textResponse}`
        };
        const updatedMessages = [...messages, pdfText];
        await updateChatMessages(user.id, currentSessionId, updatedMessages);
        const systemMessage = createSystemMessage(user);
        const response = await getResponse(updatedMessages, systemMessage);
        setQuerying(false);
        await updateChatMessages(user.id, currentSessionId, [
          ...updatedMessages,
          { role: 'assistant', content: response }
        ]);
        setFileToConvert(undefined);
      } catch (error) {
        console.error('Error converting PDF:', error);
        setSnackbarMessage('Error converting PDF.');
        setQuerying(false);
      }
    };
    reader.readAsDataURL(fileToConvert);
  }
};

export const submitQuestion = async (
  question,
  setQuerying,
  user,
  messages,
  currentSessionId,
  updateChatMessages,
  setSnackbarMessage,
  setQuestion
) => {
  if (!question.trim()) return;

  setQuestion('');
  setQuerying(true);
  try {
    const userQuestion = {
      role: 'user',
      content: question
    };

    const updatedMessages = [...messages, userQuestion];
    await updateChatMessages(user.id, currentSessionId, updatedMessages);

    const systemMessage = createSystemMessage(user);
    const response = await getResponse(
      updatedMessages,
      systemMessage,
      user.accountType === 'sage' ? 'gpt-4o' : 'gpt-4o-mini'
    );
    setQuerying(false);
    await updateChatMessages(user.id, currentSessionId, [
      ...updatedMessages,
      { role: 'assistant', content: response }
    ]);
  } catch (err) {
    console.error(
      'Error in processing a new message from the user',
      err.message,
      err
    );
    setSnackbarMessage(
      'Your Collective Council is off meditating. Guidance will return… eventually. 🌟✨'
    );
    setQuerying(false);
  }
};
