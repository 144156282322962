import { Box } from '@mui/joy';
import Lottie from 'lottie-react';
import infinity from '../lottie/loading.json';

const Loader = () => (
  <Box display="flex" justifyContent="center">
    <Lottie animationData={infinity} style={{ maxWidth: 555 }} />
  </Box>
);

export default Loader;
