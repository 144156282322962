import { Typography, Box, Grid, Button, Stack } from '@mui/joy';

const ErrorPage = () => (
  <Grid container justifyContent="center" mt={3}>
    <Grid xs={11} md={6}>
      <Stack gap={3} alignItems="center">
        <Typography level="h3">🍽️ Out to Lunch 🍽️</Typography>
        <Typography level="body-lg">
          It seems our esteemed council members are currently indulging in a
          well-deserved gastronomic escapade. They've abandoned their posts at
          the round table to recharge with some culinary delights.
        </Typography>
        <Typography level="body-lg">
          Frankly, they ought to have waited until after they'd attended to your
          needs. Alas, since they are taking this long to decide between
          tiramisu and cheesecake, please get in touch.
        </Typography>
        <Box>
          <Button
            href="https://contact.golightlyplus.com"
            variant="solid"
            target="_blank"
            component="a"
          >
            Get in Touch with the Creators of the Council
          </Button>
        </Box>
      </Stack>
    </Grid>
  </Grid>
);

export default ErrorPage;
