import { initializeApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable
  // connectFunctionsEmulator
} from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

const app = initializeApp({
  apiKey: 'AIzaSyDBI9x0xvXgQpimGJdtJNwQ0pcFZQelVhc',
  authDomain: 'openai-1111.firebaseapp.com',
  projectId: 'openai-1111',
  storageBucket: 'openai-1111.appspot.com',
  messagingSenderId: '161322896136',
  appId: '1:161322896136:web:139fc313c5231aa63ebabd',
  measurementId: 'G-M9ZNJX9GMY'
});

const functions = getFunctions(app);
// connectFunctionsEmulator(functions, 'localhost', 5001);
getAnalytics(app);

const createStripeCheckoutSession = async data => {
  const createCheckoutSession = httpsCallable(
    functions,
    'createCheckoutSession'
  );
  const response = await createCheckoutSession(data);
  return response;
};

const cancelStripeSubscription = async data => {
  const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
  const response = await cancelSubscription(data);
  return response;
};

const getResponse = async (messages, system = '', model) => {
  const chatResponse = httpsCallable(functions, 'createOpenAIMessage', {
    timeout: 11 * 60 * 1000
  });
  const response = await chatResponse({
    messages,
    system,
    model
  });
  return response.data;
};

const getSpeech = async data => {
  const textToSpeech = httpsCallable(getFunctions(), 'textToSpeech');
  const response = await textToSpeech(data);
  return response.data;
};

const convertPDFtoText = async pdf_base64 => {
  const convertPdfToText = httpsCallable(functions, 'convert_pdf_to_text');
  const result = await convertPdfToText({ pdf_base64 });
  return result.data;
};

const transcribeYouTubeVideo = async youtube_url => {
  const extractVideoId = url => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const videoId = extractVideoId(youtube_url);

  if (!videoId) {
    throw new Error('Invalid YouTube URL');
  }
  const transcribeAudio = httpsCallable(functions, 'youtubeTranscriptions');
  const result = await transcribeAudio({ videoId });
  return result.data;
};

const createImage = async (prompt, size) => {
  const generateImage = httpsCallable(functions, 'generateImage', {
    timeout: 11 * 60 * 1000
  });
  const imageUrl = await generateImage({ prompt, size });
  return imageUrl;
};

const analyseImage = async (imageUrl, text) => {
  const queryImage = httpsCallable(functions, 'queryImage', {
    timeout: 11 * 60 * 1000
  });
  const analysis = await queryImage({ imageUrl, text });
  return analysis.data.message.content;
};

const email = async ({ user, messages }) => {
  const transcript = `${messages
    .slice(1)
    .map(
      message =>
        `${
          message.role === 'user'
            ? `\n> **_${message.content}_**\n\n`
            : message.content
        }`
    )
    .join('')}`;

  const subject = (
    await getResponse([
      ...messages,
      {
        role: 'user',
        content:
          'Create an email subject from this transcript. Only return the subject with no extraneous wording so the response can be used directly in the email subject.'
      }
    ])
  ).replace(/"/g, '');

  const body = `Hello ${user.name},\n\nHere is your transcript.\n\n${transcript}\n\nThe Collective Council of Consciousness\n\n[https://collectivecouncilofconsciousness.com/](https://collectivecouncilofconsciousness.com/)`;

  console.log(user.email);
  console.log(subject);
  console.log(body);
  const sendEmail = httpsCallable(functions, 'email');
  const response = await sendEmail({
    to: user.email,
    subject,
    body
  });
  console.log('email response', response);
  return { response, subject };
};

export {
  getResponse,
  email,
  createImage,
  analyseImage,
  createStripeCheckoutSession,
  cancelStripeSubscription,
  convertPDFtoText,
  transcribeYouTubeVideo,
  getSpeech
};
