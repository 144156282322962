import {
  Button,
  Typography,
  Grid,
  Box,
  Stack,
  Divider,
  Input,
  List,
  ListItemDecorator,
  ListItem,
  ListItemContent,
  Card,
  CardContent,
  Select,
  Option,
  IconButton,
  Textarea,
  Alert,
  Snackbar,
  Switch,
  Chip,
  FormControl,
  FormLabel,
  FormHelperText
} from '@mui/joy';
import {
  Check,
  CheckCircle,
  Email,
  Groups,
  Logout,
  ManageAccounts,
  Person2,
  QuestionAnswer,
  RecordVoiceOver,
  Upgrade,
  RemoveCircleOutline,
  AddCircleOutline,
  Psychology,
  SpatialAudioOff
} from '@mui/icons-material';
import { loadStripe } from '@stripe/stripe-js';
import voicesData from '../modules/openai-voices.json';
import { useUser } from '../context/user';
import { getAuth, signOut } from 'firebase/auth';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { useState } from 'react';
import {
  createStripeCheckoutSession,
  cancelStripeSubscription
} from '../modules/firebase';

const SettingsScreen = () => {
  const { user } = useUser();
  const [councilMembers, setCouncilMembers] = useState(
    user?.council ? user.council : []
  );
  const [preferences, setPreferences] = useState(user?.preferences);
  const [name, setName] = useState(user?.name);
  const [voice, setVoice] = useState(
    user?.voice ? user.voice : voicesData.voices[0].voice
  );
  const [email, setEmail] = useState(user?.email);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [creatingCheckoutSession, setCreatingCheckoutSession] = useState(false);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  // Add a function to handle the 'Upgrade Now' button click
  const handleUpgrade = async () => {
    setCreatingCheckoutSession(true);
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;

    console.log('user', user);
    const { data: session } = await createStripeCheckoutSession({
      customer_email: user?.email,
      userId: user?.id
    });

    // Redirect the customer to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.id
    });

    if (result.error) {
      // Inform the customer there was an error
      alert(result.error.message);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid lg={5} md={6} xs={11} sx={{ mt: 11, mb: 8 }}>
        <Stack spacing={3}>
          <Card variant="outlined">
            <Typography level="h2" fontSize="xl" startDecorator={<Groups />}>
              Council Members
            </Typography>
            <Divider />
            <Typography level="body-sm">
              Your council member choices will tailor the language and style of
              the responses.
            </Typography>
            <CardContent>
              <Stack gap={2}>
                {councilMembers.map((member, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <Input
                      fullWidth
                      value={member}
                      onChange={event => {
                        const newCouncilMembers = [...councilMembers];
                        newCouncilMembers[index] = event.target.value;
                        setCouncilMembers(newCouncilMembers);
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        const newCouncilMembers = [...councilMembers];
                        newCouncilMembers.splice(index, 1);
                        setCouncilMembers(newCouncilMembers);
                      }}
                      aria-label="delete"
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
              <Box my={3}>
                <Button
                  startDecorator={<AddCircleOutline />}
                  onClick={() => setCouncilMembers([...councilMembers, ''])}
                >
                  Add Member
                </Button>
              </Box>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'end', p: 2 }}>
              <Button
                disabled={
                  JSON.stringify(councilMembers) ===
                  JSON.stringify(user?.council)
                }
                onClick={async () => {
                  await updateDoc(doc(getFirestore(), 'users', user.id), {
                    council: councilMembers
                  });
                  setShowSnackbar(true);
                }}
              >
                Save
              </Button>
            </Box>
          </Card>
          <Card variant="outlined">
            <Typography
              level="h2"
              fontSize="xl"
              startDecorator={<Psychology />}
            >
              Preferences
            </Typography>
            <Divider />
            <FormControl
              orientation="horizontal"
              sx={{ justifyContent: 'space-between' }}
            >
              <Box>
                <FormLabel>
                  <Typography>
                    Feel the vibration in your device as the CC ponders.
                  </Typography>
                </FormLabel>
              </Box>
              <Switch
                checked={user.vibration}
                onChange={async event => {
                  await updateDoc(doc(getFirestore(), 'users', user.id), {
                    vibration: event.target.checked
                  });
                  setShowSnackbar(true);
                }}
                color={user.vibration ? 'success' : 'neutral'}
                variant={user.vibration ? 'solid' : 'outlined'}
                endDecorator={user.vibration ? 'Enabled' : 'Disabled'}
              />
            </FormControl>
            <Divider />
            <FormControl
              orientation="horizontal"
              disabled={user.accountType !== 'sage'}
              sx={{ justifyContent: 'space-between' }}
            >
              <Box>
                <FormLabel>
                  <Typography
                    endDecorator={
                      <Chip color="primary" size="sm" variant="outlined">
                        Sage
                      </Chip>
                    }
                  >
                    Let the council learn about you as you chat with them{' '}
                  </Typography>
                </FormLabel>
                <FormHelperText>
                  This will help personalise your experience.
                </FormHelperText>
              </Box>
              <Switch
                checked={user.learning}
                onChange={async event => {
                  await updateDoc(doc(getFirestore(), 'users', user.id), {
                    learning: event.target.checked
                  });
                  setShowSnackbar(true);
                }}
                color={user.learning ? 'success' : 'neutral'}
                variant={user.learning ? 'solid' : 'outlined'}
                endDecorator={user.learning ? 'Enabled' : 'Disabled'}
              />
            </FormControl>
            <Divider />
            <Typography level="body-sm">
              Define any preferences you have as to how the council should
              process your queries. e.g. healthcare beliefs, your personal views
              on reality, dietary choices.
            </Typography>
            <Textarea
              value={preferences}
              onChange={e => setPreferences(e.target.value)}
              endDecorator={
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
                  <Button
                    disabled={preferences === user?.preferences}
                    onClick={async () => {
                      await updateDoc(doc(getFirestore(), 'users', user.id), {
                        preferences
                      });
                      setShowSnackbar(true);
                    }}
                  >
                    Save
                  </Button>
                </Box>
              }
            />
          </Card>
          <Card variant="outlined">
            <Box display="flex" justifyContent="space-between">
              <Typography
                level="h2"
                fontSize="xl"
                startDecorator={<ManageAccounts />}
              >
                Account details
              </Typography>
            </Box>
            <Divider inset="none" />
            <CardContent>
              <Input
                startDecorator={<Person2 />}
                onChange={event => setName(event.target.value)}
                value={name}
                endDecorator={
                  <Button
                    disabled={name === user?.name}
                    onClick={async () => {
                      await updateDoc(doc(getFirestore(), 'users', user.id), {
                        name
                      });
                      setShowSnackbar(true);
                    }}
                    size="sm"
                  >
                    Update
                  </Button>
                }
              />
              <Input
                startDecorator={<Email />}
                onChange={event => setEmail(event.target.value)}
                value={email}
                endDecorator={
                  <Button
                    disabled={email === user?.email}
                    onClick={async () => {
                      await updateDoc(doc(getFirestore(), 'users', user.id), {
                        email
                      });
                      setShowSnackbar(true);
                    }}
                    size="sm"
                  >
                    Update
                  </Button>
                }
              />
              <Alert variant="soft" sx={{ my: 1 }}>
                <Box>
                  <Typography level="body-lg" marginBottom={1}>
                    Account type:{' '}
                    {user?.accountType === 'sage'
                      ? 'Sage Premium Plan'
                      : 'Free Plan'}
                  </Typography>
                  {user?.accountType === 'sage' && (
                    <Stack spacing={3}>
                      <Typography level="body-md">
                        You have access to all features! 😊
                      </Typography>

                      <Button
                        color="danger"
                        size="sm"
                        variant="plain"
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to cancel your subscription?'
                            )
                          ) {
                            cancelStripeSubscription({
                              subscriptionId: user.subscriptionId
                            }).then(() => {
                              setShowSnackbar(true);
                            });
                          }
                        }}
                      >
                        Cancel Subscription
                      </Button>
                    </Stack>
                  )}
                  {user?.accountType !== 'sage' && (
                    <Stack spacing={2}>
                      <Typography level="body-sm">
                        What's Included in the Free Plan:
                      </Typography>
                      <List aria-labelledby="decorated-list-demo">
                        {[
                          'Experience the forefront of AI technology with the most sophisticated chat engine available.',
                          'Animate your interactions with a select assortment of emotive voices, available at no cost.'
                        ].map((feature, index) => (
                          <ListItem key={index}>
                            <ListItemDecorator>
                              <CheckCircle color="success" />
                            </ListItemDecorator>
                            {feature}
                          </ListItem>
                        ))}
                      </List>
                      <Typography
                        level="body-sm"
                        endDecorator={
                          <Chip color="primary" size="sm" variant="outlined">
                            Sage
                          </Chip>
                        }
                      >
                        Explore Beyond with the Sage Premium Plan:
                      </Typography>
                      <List aria-labelledby="decorated-list-demo">
                        {[
                          'Unlock advanced text-to-image generation for creating vivid visual content.',
                          'Embed images directly into your chats for a richer dialogue experience.',
                          'Enjoy more personalized responses as the Collective Council learns from your interactions.'
                        ].map((feature, index) => (
                          <ListItem key={index}>
                            <ListItemDecorator>
                              <CheckCircle color="success" />
                            </ListItemDecorator>
                            {feature}
                          </ListItem>
                        ))}
                      </List>
                      <Typography
                        level="body-md"
                        endDecorator={
                          <Button
                            loading={creatingCheckoutSession}
                            startDecorator={<Upgrade />}
                            onClick={() => handleUpgrade()}
                          >
                            Upgrade Now
                          </Button>
                        }
                      >
                        Unlock the full potential of your creative expression.
                      </Typography>
                      <Typography level="body-sm">
                        Subscribe to the Sage Premium Plan at £11.11 per month.
                        Cancel anytime.
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Alert>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <Box display="flex" justifyContent="space-between">
              <Typography
                level="h2"
                fontSize="xl"
                startDecorator={<QuestionAnswer />}
              >
                Voice settings
              </Typography>
            </Box>
            <Divider inset="none" />
            <CardContent>
              <Typography level="body-md" gutterBottom>
                You can have the council's responses read to you.
              </Typography>
              <Typography level="body-md">
                Please choose your preferred voice.
              </Typography>
              <List>
                <ListItem>
                  <ListItemDecorator>
                    <RecordVoiceOver color="info" />
                  </ListItemDecorator>
                  <ListItemContent>Voice</ListItemContent>
                  <Select
                    onChange={async (evt, voice) => {
                      setVoice(voice);
                      await updateDoc(doc(getFirestore(), 'users', user.id), {
                        voice
                      });
                      setShowSnackbar(true);
                    }}
                    value={voice}
                  >
                    {voicesData.voices.map((voice, i) => (
                      <Option value={voicesData.voices[i].voice} key={i}>
                        {voicesData.voices[i].display}
                      </Option>
                    ))}
                  </Select>
                </ListItem>
              </List>
              <Typography level="body-xs" endDecorator={<SpatialAudioOff />}>
                To have a response read to you in the chat, just click this icon
              </Typography>
            </CardContent>
          </Card>

          <Button
            variant="plain"
            startDecorator={<Logout />}
            onClick={() => signOut(getAuth())}
          >
            Log out
          </Button>
        </Stack>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          color="success"
          size="sm"
          variant="outlined"
          startDecorator={<Check />}
          open={showSnackbar}
          onClose={() => setShowSnackbar(false)}
        >
          Settings updated!
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default SettingsScreen;
