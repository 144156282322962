import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/joy';

const ImageModal = ({ open, handleImageUpload, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog>
        <DialogTitle>Insert an image</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ImageModal;
