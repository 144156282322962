import { useState, useRef, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  Alert,
  Stack,
  LinearProgress,
  Snackbar,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  ModalClose,
  Button
} from '@mui/joy';
import { Edit, NoteAdd, ForwardToInbox } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { useUser } from '../context/user';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { onSnapshot, getDoc } from 'firebase/firestore';
import {
  handleImageUpload,
  handleYouTubeTranscription,
  handleFileConvert,
  submitQuestion
} from '../utils/handleMethods';
import useVibration from '../utils/useVibration';
import {
  getChatDocRef,
  updateChatMessages,
  createChatSession
} from '../utils/firebaseUtils';
import HeaderActions from './HeaderActions';
import MessageHistory from './MessageHistory';
import InputArea from './InputArea';
import ImageModal from './ImageModal';
import PDFModal from './PDFModal';
import Loader from './Loader';
import YouTubeModal from './YouTubeModal';
import SpeechPlayer from './SpeechPlayer';

const ChatMode = () => {
  const [querying, setQuerying] = useState(false);
  const [question, setQuestion] = useState('');
  const [openYouTubeDialog, setOpenYouTubeDialog] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [isFetchingSpeech, setIsFetchingSpeech] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { user } = useUser();
  const [speech, setSpeech] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [confirmChatDeletion, setConfirmChatDeletion] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState();
  const [fileToConvert, setFileToConvert] = useState();
  const chatRef = useRef();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [messages, setMessages] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(null);

  useVibration(querying, user);

  const startNewChatSession = useCallback(async () => {
    console.log('starting new chat session...');
    const sessionId = await createChatSession(user);
    console.log('sessionId', sessionId);
    if (!sessionId) return;
    setCurrentSessionId(sessionId);
    localStorage.setItem('currentSessionId', sessionId);
  }, [user]);

  useEffect(() => {
    if (!user?.id) return;

    const fetchMessages = async () => {
      const sessionId = localStorage.getItem('currentSessionId');
      if (sessionId) {
        const chatDocRef = getChatDocRef(user.id, sessionId);
        const chatDoc = await getDoc(chatDocRef);

        if (chatDoc.exists()) {
          setCurrentSessionId(sessionId);
        } else {
          startNewChatSession();
        }
      } else {
        startNewChatSession();
      }
    };

    fetchMessages();
  }, [user, startNewChatSession]);

  useEffect(() => {
    if (!currentSessionId || !user?.id) return;

    const chatDocRef = getChatDocRef(user.id, currentSessionId);
    const unsubscribe = onSnapshot(chatDocRef, doc => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
      }
    });

    return () => unsubscribe();
  }, [currentSessionId, user?.id]);

  useEffect(() => {
    if (chatRef.current) {
      const { scrollHeight, clientHeight } = chatRef.current;
      chatRef.current.scrollTo({
        top: scrollHeight - clientHeight,
        behavior: 'smooth'
      });
    }
  }, [messages]);

  const handleFileInput = event => {
    const file = event.target.files[0];
    if (file) {
      setFileToConvert(file);
    }
  };

  let dashboard;

  if (user) {
    if (messages.length > 1) {
      dashboard = (
        <MessageHistory
          messages={messages}
          user={user}
          isFetchingSpeech={isFetchingSpeech}
          setIsFetchingSpeech={setIsFetchingSpeech}
          setSnackbarMessage={setSnackbarMessage}
          setSpeech={setSpeech}
        />
      );
    } else {
      dashboard = (
        <Alert
          sx={{ justifyContent: 'center', mt: 3 }}
          endDecorator={
            <Button
              size="sm"
              variant="soft"
              startDecorator={<Edit />}
              onClick={() => navigate('/dashboard/settings')}
            >
              Edit council
            </Button>
          }
        >
          <Stack spacing={1}>
            <Typography level="title-md" textAlign="center">
              Your council is {user?.council?.join(', ')}
            </Typography>
            <Typography level="body1" textAlign="center">
              Ask anything!
            </Typography>
          </Stack>
        </Alert>
      );
    }
  } else {
    dashboard = <Loader />;
  }

  let inputArea;

  if (isFetchingSpeech || sendingEmail) {
    inputArea = (
      <Box my={3}>
        <LinearProgress />
      </Box>
    );
  } else if (speech) {
    inputArea = (
      <SpeechPlayer
        completed={() => setSpeech(null)}
        cancelled={() => setSpeech(null)}
        speech={speech}
      />
    );
  } else {
    inputArea = (
      <InputArea
        question={question}
        setQuestion={setQuestion}
        submitQuestion={() =>
          submitQuestion(
            question,
            setQuerying,
            user,
            messages,
            currentSessionId,
            updateChatMessages,
            setSnackbarMessage,
            setQuestion
          )
        }
        querying={querying}
        user={user}
        isMobile={isMobile}
        setOpenImageDialog={setOpenImageDialog}
        setOpenDialog={setOpenDialog}
        setOpenYouTubeDialog={setOpenYouTubeDialog}
      />
    );
  }

  console.log(messages);

  return (
    <>
      <HeaderActions
        querying={querying}
        messages={messages}
        user={user}
        setConfirmChatDeletion={setConfirmChatDeletion}
        setSendingEmail={setSendingEmail}
        setEmailSent={setEmailSent}
      />
      <Grid container justifyContent="center">
        <Grid lg={7} md={9} xs={12} sm={11}>
          <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            paddingTop={8}
          >
            <Card
              sx={{
                flexGrow: 1,
                overflow: 'auto',
                scrollBehavior: 'smooth',
                mb: 2
              }}
              ref={chatRef}
            >
              {dashboard}
              {querying && <Loader />}
            </Card>
            {inputArea}
          </Box>
        </Grid>
        <ImageModal
          open={openImageDialog}
          handleImageUpload={event =>
            handleImageUpload(
              event,
              setOpenImageDialog,
              setQuerying,
              user,
              messages,
              currentSessionId,
              updateChatMessages,
              setSnackbarMessage
            )
          }
          handleClose={() => setOpenImageDialog(false)}
        />
        <PDFModal
          open={openDialog}
          handleFileInput={handleFileInput}
          handleFileConvert={() =>
            handleFileConvert(
              fileToConvert,
              setQuerying,
              setOpenDialog,
              user,
              messages,
              currentSessionId,
              updateChatMessages,
              setSnackbarMessage,
              setFileToConvert
            )
          }
          handleClose={() => setOpenDialog(false)}
          fileToConvert={fileToConvert}
        />
        <YouTubeModal
          open={openYouTubeDialog}
          youtubeUrl={youtubeUrl}
          setYoutubeUrl={setYoutubeUrl}
          handleYouTubeTranscription={() =>
            handleYouTubeTranscription(
              youtubeUrl,
              setQuerying,
              setOpenYouTubeDialog,
              user,
              messages,
              currentSessionId,
              updateChatMessages,
              setSnackbarMessage,
              setYoutubeUrl
            )
          }
          handleClose={() => setOpenYouTubeDialog(false)}
        />
        <Snackbar
          open={!!snackbarMessage && !isMobile}
          onClose={() => setSnackbarMessage(null)}
          color="primary"
        >
          {snackbarMessage}
        </Snackbar>
      </Grid>
      <Modal
        open={confirmChatDeletion}
        onClose={() => setConfirmChatDeletion(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <NoteAdd />
            New chat
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="body-lg">Want to start a new chat?</Typography>
            <Typography level="body-sm">
              This will delete your current chat.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="success"
              onClick={() => {
                setConfirmChatDeletion(false);
                startNewChatSession();
              }}
            >
              Yes, please
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setConfirmChatDeletion(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Modal open={!!emailSent} onClose={() => setEmailSent(null)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h3" startDecorator={<ForwardToInbox />}>
            Email sent!
          </Typography>
          <Typography level="body-md">
            Check your {user?.email} inbox for an email with the subject "
            {emailSent}".
          </Typography>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ChatMode;
