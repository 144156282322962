import React from 'react';
import {
  Box,
  Textarea,
  IconButton,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Chip
} from '@mui/joy';
import { Send, AttachFile } from '@mui/icons-material';

const InputArea = ({
  question,
  setQuestion,
  submitQuestion,
  querying,
  user,
  isMobile,
  setOpenImageDialog,
  setOpenDialog,
  setOpenYouTubeDialog
}) => {
  return (
    <Box
      justifyContent="space-evenly"
      display="flex"
      alignItems="flex-end"
      mb={2}
    >
      <Dropdown>
        <MenuButton slots={{ root: IconButton }} sx={{ mb: 1, mr: 1 }}>
          <AttachFile />
        </MenuButton>
        <Menu>
          <MenuItem
            onClick={() => setOpenImageDialog(true)}
            disabled={user?.accountType !== 'sage'}
          >
            Image{' '}
            <Chip color="primary" size="sm" variant="outlined">
              Sage
            </Chip>
          </MenuItem>
          <MenuItem onClick={() => setOpenDialog(true)}>PDF</MenuItem>
          <MenuItem onClick={() => setOpenYouTubeDialog(true)}>
            YouTube transcript
          </MenuItem>
        </Menu>
      </Dropdown>
      <Textarea
        placeholder="What's on your mind?"
        size="lg"
        disabled={!user}
        value={question}
        onChange={event => {
          if (
            !(event.target.value.length === 1 && event.target.value === '\n')
          ) {
            setQuestion(event.target.value);
          }
        }}
        sx={{ flex: 1 }}
        onKeyDown={event => {
          if (!querying) {
            if (
              event.key === 'Enter' &&
              !isMobile &&
              !event.shiftKey &&
              question.length > 0
            ) {
              submitQuestion();
            }
          }
        }}
      />
      <IconButton
        sx={{ ml: 2, mb: 1, mr: 1 }}
        variant="solid"
        disabled={querying || !question}
        onClick={submitQuestion}
      >
        <Send />
      </IconButton>
    </Box>
  );
};

export default InputArea;
