import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/joy';

const PDFModal = ({
  open,
  handleFileInput,
  handleFileConvert,
  handleClose,
  fileToConvert
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog>
        <DialogTitle>Insert a PDF</DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileConvert} disabled={!fileToConvert}>
            Insert file
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PDFModal;
