import { useEffect } from 'react';

const vibrationPattern = [
  22, 0, 0, 1, 0, 1, 41, 2, 0, 2, 0, 3, 22, 4, 0, 5, 0, 6, 32, 7, 21, 7, 0, 8,
  36, 9, 23, 10, 0, 11, 32, 11, 0, 12, 0, 13, 35, 14, 118, 14, 32, 15, 0, 16, 0,
  16, 0, 17, 0, 17, 84, 18, 45, 18, 20, 19, 71, 19, 0, 20, 22, 20, 22, 21, 0,
  21, 21, 21, 30, 22, 65, 22, 0, 22, 0, 23, 0, 23, 0, 23, 19, 23, 18, 24, 90,
  24, 0, 24, 0, 24, 28, 24, 0, 24, 0, 25, 0, 25, 0, 25, 53, 25, 22, 25, 29, 25,
  19, 25, 0, 25, 41
];

const patternDuration = vibrationPattern.reduce((acc, value) => acc + value, 0);

const useVibration = (querying, user) => {
  useEffect(() => {
    if (!('vibrate' in navigator)) return;

    let vibrationInterval;
    if (querying && user.vibration) {
      navigator.vibrate(vibrationPattern);
      vibrationInterval = setInterval(() => {
        navigator.vibrate(vibrationPattern);
      }, patternDuration);
    } else {
      navigator.vibrate(0);
    }

    return () => {
      if (vibrationInterval) {
        clearInterval(vibrationInterval);
      }
      navigator.vibrate(0);
    };
  }, [querying, user?.vibration]);
};

export default useVibration;
