import { useState } from 'react';
import {
  Grid,
  Textarea,
  Typography,
  Box,
  Divider,
  Stack,
  AspectRatio,
  Button,
  Alert,
  Accordion,
  AccordionGroup,
  AccordionSummary,
  AccordionDetails,
  Select,
  Option
} from '@mui/joy';
import Lottie from 'lottie-react';
import thinking from '../lottie/loading.json';
import artist from '../lottie/artist.json';
import { createImage } from '../modules/firebase';
import { Image, Lock, OpenInNew, WarningAmber } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/user';

const demoImages = [
  {
    prompt: 'cave full of rainbow crystals',
    url: 'https://i.ibb.co/mGZJwfR/crystal-cave.png'
  },
  {
    prompt: 'meadow with grass and butterflies with rainbows',
    url: 'https://i.ibb.co/9G1fHy1/meadow.png'
  },
  {
    prompt: 'looking at planets and stars from space',
    url: 'https://i.ibb.co/Z8vdgcf/space.png'
  }
];

const Images = () => {
  const [querying, setQuerying] = useState(false);
  const [imageDescription, setImageDescription] = useState('');
  const [imageAspectRatio, setImageAspectRatio] = useState('1024x1024'); // Default to Square
  const [imageUrl, setImageUrl] = useState();
  const [demoImageIndex, setDemoImageIndex] = useState(0);
  const [error, setError] = useState();
  const { user } = useUser();
  const navigate = useNavigate();

  const submitImageDescription = async () => {
    try {
      setError(undefined);
      setQuerying(true);
      setImageUrl(null);
      const imageUrlRes = await createImage(imageDescription, imageAspectRatio);
      setImageUrl(imageUrlRes.data);
      setQuerying(false);
    } catch (err) {
      console.error(err.code, err.message, err.details);
      if (err.details.code === 'content_policy_violation') {
        setError(
          'Sorry, this prompt is not appropriate for this app. Try something more wholesome. 😇'
        );
      }
      setQuerying(false);
    }
  };

  let dashboard;

  if (user?.accountType === 'sage') {
    dashboard = (
      <>
        {error && (
          <Alert color="warning" startDecorator={<WarningAmber />}>
            {error}
          </Alert>
        )}
        <Textarea
          placeholder="Describe your image..."
          size="lg"
          autoFocus
          value={imageDescription}
          onChange={event => setImageDescription(event.target.value)}
        />
        <Select
          label="Choose image aspect ratio:"
          value={imageAspectRatio}
          onChange={(event, newValue) => setImageAspectRatio(newValue)}
        >
          <Option value="1024x1024">Square (1024x1024)</Option>
          <Option value="1792x1024">Landscape (1792x1024)</Option>
          <Option value="1024x1792">Portrait (1024x1792)</Option>
        </Select>

        <Button
          disabled={querying || !imageDescription}
          size="lg"
          onClick={submitImageDescription}
        >
          Generate image
        </Button>
      </>
    );
  } else {
    dashboard = (
      <Alert
        color="primary"
        startDecorator={<Lock />}
        endDecorator={
          <Button onClick={() => navigate('/dashboard/settings')}>
            Sage Plan details
          </Button>
        }
      >
        <Stack>
          <Typography level="body-md">
            Only Sage users can generate images.
          </Typography>
          <Typography level="body-sm">
            Discover the transformative power of generating captivating visuals
            from words!
          </Typography>
        </Stack>
      </Alert>
    );
  }

  return (
    <Grid container>
      <Grid xs={12} md={6} lg={4} sx={{ pt: 8 }}>
        <Stack spacing={3} justifyContent="center" padding={3}>
          <Typography level="h2" startDecorator={<Image />}>
            Image generation
          </Typography>
          <Typography level="body-md">Create an image from text.</Typography>
          {dashboard}
        </Stack>
      </Grid>
      <Grid
        xs={12}
        md={6}
        lg={8}
        paddingX={{ xs: 3, lg: 18 }}
        paddingTop={{ lg: 8, xs: 3 }}
      >
        {!imageUrl && !querying && user?.accountType === 'sage' && (
          <Box paddingX={8} paddingTop={3}>
            <AspectRatio
              ratio={1}
              variant="outlined"
              sx={{ borderRadius: 'sm' }}
            >
              <Lottie animationData={artist} />
            </AspectRatio>
          </Box>
        )}
        {user?.accountType !== 'sage' && (
          <Stack spacing={1}>
            <Typography level="title-md">
              Explore Our Gallery of Past Imagery Crafted with This
              Text-to-Image Generator
            </Typography>
            <Divider />
            <AccordionGroup>
              {demoImages.map((image, index) => (
                <Accordion
                  key={index}
                  expanded={index === demoImageIndex}
                  onChange={(event, expanded) => {
                    setDemoImageIndex(expanded ? index : null);
                  }}
                >
                  <AccordionSummary>"{image.prompt}"</AccordionSummary>
                  <AccordionDetails>
                    <AspectRatio
                      ratio={1}
                      variant="outlined"
                      sx={{ borderRadius: 'sm' }}
                    >
                      <img src={image.url} alt={image.prompt} />
                    </AspectRatio>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionGroup>
          </Stack>
        )}
        {imageUrl && (
          <Stack spacing={1}>
            <AspectRatio
              ratio={imageAspectRatio.replace('x', '/')}
              variant="outlined"
              sx={{ borderRadius: 'sm' }}
            >
              <img src={imageUrl} loading="lazy" alt={imageDescription} />
            </AspectRatio>
            <Box display="flex" justifyContent="center" my={3}>
              <Button
                component="a"
                href={imageUrl}
                target="_blank"
                startDecorator={<OpenInNew />}
              >
                Open in new tab
              </Button>
            </Box>
          </Stack>
        )}
        {querying && <Lottie animationData={thinking} />}
      </Grid>
    </Grid>
  );
};

export default Images;
