import {
  AspectRatio,
  Typography,
  Button,
  Container,
  Grid,
  Stack,
  Input,
  FormLabel,
  FormControl,
  Alert,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Link,
  IconButton
} from '@mui/joy';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import {
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { useUser } from '../context/user';
import meditate from '../lottie/meditate.json';

const LandingPage = () => {
  const { loading } = useUser();
  const [error, setError] = useState('');
  const [processingAuth, setProcessingAuth] = useState(false);
  const [showResetLink, setShowResetLink] = useState(false);
  const [showThePassword, setShowThePassword] = useState(false);

  const handlePasswordReset = async email => {
    try {
      await sendPasswordResetEmail(getAuth(), email);
      alert('Password reset email sent!');
    } catch (error) {
      console.error('error', error);
      setError('Error sending password reset email. Please try again.');
    }
  };

  const loginValidationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: async values => {
      setProcessingAuth(true);
      setShowResetLink(false); // Reset before attempt
      try {
        await signInWithEmailAndPassword(
          getAuth(),
          values.email,
          values.password
        );
      } catch (error) {
        console.error('error', error);
        switch (error.code) {
          case 'auth/wrong-password':
            setError('Incorrect password. Please try again.');
            setShowResetLink(true);
            break;
          case 'auth/user-not-found':
            setError('No user found with this email address.');
            break;
          default:
            setError('An error occurred. Please try again.');
        }
      }
      setProcessingAuth(false);
    }
  });

  const registerValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
  });

  const registerFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: ''
    },
    validationSchema: registerValidationSchema,
    onSubmit: async values => {
      setProcessingAuth(true);
      try {
        const userCredential = await createUserWithEmailAndPassword(
          getAuth(),
          values.email,
          values.password
        );

        await setDoc(doc(getFirestore(), 'users', userCredential.user.uid), {
          name: values.name.trim(),
          email: values.email,
          council: []
        });

        console.log('created account for', values.name, values.email);
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          registerFormik.setFieldError('email', 'Email is already registered');
        } else {
          console.error('error', error);
          setError('An error occurred. Please try again.');
        }
      }
      setProcessingAuth(false);
    }
  });

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Lottie animationData={meditate} />
      </Box>
    );
  }

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        sx={{
          minHeight: '100vh'
        }}
      >
        <Grid xs={12} lg={6} display={{ xs: 'none', lg: 'block', padding: 11 }}>
          <AspectRatio
            variant="outlined"
            minHeight={555}
            sx={{ borderRadius: 'sm' }}
          >
            <img src="https://i.ibb.co/b7q3q5D/ccc.png" alt="CCC logo" />
          </AspectRatio>
        </Grid>
        <Grid xs={12} lg={6} p={3}>
          <Stack spacing={3}>
            <Typography level="h2">The Collective Council</Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: '15px',
                  sm: '18px'
                }
              }}
            >
              Your Odyssey of Enlightenment Begins Here.
            </Typography>
            {error && <Alert severity="danger">{error}</Alert>}
            <Tabs defaultValue={0}>
              <TabList>
                <Tab>Login</Tab>
                <Tab>Register</Tab>
              </TabList>
              <TabPanel value={0}>
                <form onSubmit={loginFormik.handleSubmit}>
                  <Stack spacing={2} noValidate autoComplete="off">
                    <Typography level="body-lg">Welcome back!</Typography>
                    <FormControl>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        required
                        type="email"
                        {...loginFormik.getFieldProps('email')}
                        error={
                          loginFormik.touched.email && loginFormik.errors.email
                        }
                      />
                      {loginFormik.touched.email &&
                        loginFormik.errors.email && (
                          <Typography level="body-sm" color="danger">
                            {loginFormik.errors.email}
                          </Typography>
                        )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Password</FormLabel>
                      <Input
                        required
                        type={showThePassword ? 'text' : 'password'}
                        {...loginFormik.getFieldProps('password')}
                        error={
                          loginFormik.touched.password &&
                          loginFormik.errors.password
                        }
                        endDecorator={
                          <IconButton
                            onClick={() => setShowThePassword(!showThePassword)}
                          >
                            {showThePassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                      />
                      {loginFormik.touched.password &&
                        loginFormik.errors.password && (
                          <Typography level="body-sm" color="danger">
                            {loginFormik.errors.password}
                          </Typography>
                        )}
                    </FormControl>

                    <Button type="submit" size="lg" loading={processingAuth}>
                      Login
                    </Button>
                    {showResetLink && (
                      <Box mt={2}>
                        <Link
                          onClick={() =>
                            handlePasswordReset(loginFormik.values.email)
                          }
                          level="body-sm"
                        >
                          Forgot your password?
                        </Link>
                      </Box>
                    )}
                  </Stack>
                </form>
              </TabPanel>
              <TabPanel value={1}>
                <form onSubmit={registerFormik.handleSubmit}>
                  <Stack spacing={2} noValidate autoComplete="off">
                    <Typography level="body-md">
                      Need an account? Sign up!
                    </Typography>
                    <FormControl>
                      <FormLabel>Name</FormLabel>
                      <Input
                        required
                        type="text"
                        {...registerFormik.getFieldProps('name')}
                        error={
                          registerFormik.touched.name &&
                          registerFormik.errors.name
                        }
                      />
                      {registerFormik.touched.name &&
                        registerFormik.errors.name && (
                          <Typography level="body-sm" color="danger">
                            {registerFormik.errors.name}
                          </Typography>
                        )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        required
                        type="email"
                        {...registerFormik.getFieldProps('email')}
                        error={
                          registerFormik.touched.email &&
                          registerFormik.errors.email
                        }
                      />
                      {registerFormik.touched.email &&
                        registerFormik.errors.email && (
                          <Typography level="body-sm" color="danger">
                            {registerFormik.errors.email}
                          </Typography>
                        )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Password</FormLabel>
                      <Input
                        required
                        type={showThePassword ? 'text' : 'password'}
                        {...registerFormik.getFieldProps('password')}
                        error={
                          registerFormik.touched.password &&
                          registerFormik.errors.password
                        }
                        endDecorator={
                          <IconButton
                            onClick={() => setShowThePassword(!showThePassword)}
                          >
                            {showThePassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                      />
                      {registerFormik.touched.password &&
                        registerFormik.errors.password && (
                          <Typography level="body-sm" color="danger">
                            {registerFormik.errors.password}
                          </Typography>
                        )}
                    </FormControl>
                    <Button type="submit" size="lg" loading={processingAuth}>
                      Register
                    </Button>
                  </Stack>
                </form>
              </TabPanel>
            </Tabs>
            <Box textAlign="right">
              <Typography level="body-sm">
                Any issues or questions, please email us.
              </Typography>
              <Link
                href="mailto:contact@collectivecouncilofconsciousness.com"
                level="body-sm"
              >
                contact@collectivecouncilofconsciousness.com
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
