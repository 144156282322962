import { doc, getFirestore, getDoc } from 'firebase/firestore';
import { getSpeech } from './firebase';

const playCreatorMessage = async text => {
  const { message } = (
    await getDoc(doc(getFirestore(), 'easter-eggs', 'creator'))
  ).data();
  const base64Audio = await getSpeech({ text: message });
  const audioElement = new Audio(`data:audio/wav;base64,${base64Audio}`);
  await audioElement.play();
};

export { playCreatorMessage };
