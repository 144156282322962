import {
  doc,
  getFirestore,
  updateDoc,
  addDoc,
  collection,
  Timestamp
} from 'firebase/firestore';

export const getChatDocRef = (userId, sessionId) => {
  return doc(getFirestore(), 'users', userId, 'chats', sessionId);
};

export const updateChatMessages = async (
  userId,
  sessionId,
  updatedMessages
) => {
  const chatDocRef = getChatDocRef(userId, sessionId);
  await updateDoc(chatDocRef, {
    messages: updatedMessages,
    detailsExtracted: false
  });
};

export const createChatSession = async user => {
  try {
    const chatSessionRef = await addDoc(
      collection(getFirestore(), 'users', user.id, 'chats'),
      {
        messages: [],
        sessionStarted: Timestamp.now(),
        detailsExtracted: false
      }
    );
    return chatSessionRef.id;
  } catch (error) {
    console.error('Error creating chat session:', error);
  }
};
